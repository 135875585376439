<template>
  <li :class="`accordion-child accordion-child-level-${level}`">
    <button
      @click="toggle"
      class="accordion-child-label"
      :class="[{ 'accordion-child-label--active': isOpen }]"
    >
      <span class="accordion-child-label-name">{{ title }}</span>
      <div class="accordion-child-label-details">
        <span class="accordion-child-label-details-label">{{ detailsText }}</span>
        <FontAwesomeIcon :icon="detailsIcon" />
      </div>
    </button>
    <template v-if="isOpen">
      <div
        v-if="content && content !== '<br/>' && !fields.length"
        class="accordion-child-content"
        style="white-space: pre-line"
        v-html="content"
      ></div>
      <ul class="accordion-child-wrapper" v-if="fields.length">
        <ChildrenComponent
          v-for="(child, index) in fields"
          :key="index"
          :content="child.content"
          :title="child.title"
          :fields="child.fields || []"
          :level="level + 1"
          :openAll="openAll"
        ></ChildrenComponent>
      </ul>
    </template>
  </li>
</template>

<script>
export default {
  props: {
    content: String,
    title: String,
    level: Number,
    openAll: Boolean,
    fields: Array,
  },

  watch: {
    openAll: {
      handler() {
        this.isOpen = this.openAll
      },
      immediate: true,
    },
  },

  data() {
    return {
      isOpen: false,
    }
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen
    },
  },
  computed: {
    detailsText() {
      return this.isOpen ? 'Lukk detaljer' : 'Vis detaljer'
    },

    detailsIcon() {
      return this.isOpen ? ['fas', 'chevron-up'] : ['fas', 'chevron-down']
    },
  },
  // for recursive nesting
  beforeCreate: function() {
    this.$options.components.ChildrenComponent = require('./ChildrenComponent.vue').default
  },
}
</script>

<style lang="scss">
.accordion-child {
  &-level-0 {
    margin-bottom: 1.25rem;
  }

  $primary-hover: #ede0cc;
  $primary-active: #f5f0e6;
  $secondary-hover: black;
  $secondary-active: black;

  $navbar: 90px;
  $goldenHeader: 58px;
  $childHeader: 63px;
  $levels: 5;

  &-wrapper {
    list-style-type: none;
    padding-inline-start: 0;

    &:last-child &:not(:has(.accordion-child-wrapper)) {
      padding-bottom: 3rem;
    }
  }

  &-content {
    padding: 2rem;
    max-width: 1000px;

    &:last-child {
      padding-bottom: 3rem;
    }
  }

  &-label {
    width: 100%;
    display: flex;
    justify-content: space-between;

    &-details {
      display: flex;
      &-label {
        @include respond-below('phone') {
          display: none;
        }
      }
    }

    svg {
      margin-left: 1rem;
      padding: 5px;
      background-color: inherit;
      border-radius: 100%;
    }
  }

  &-level-0 > * > .accordion-child-label-name {
    font-weight: bold;
    text-transform: uppercase;
  }

  &-level-0 > * > .accordion-child-label-details {
    svg {
      color: white;
      background-color: var(--secondary);
    }
  }

  &-level-0 > .accordion-child-label {
    position: sticky;
    top: #{$navbar};
    z-index: #{$levels};

    color: var(--secondary);
    background-color: var(--primary);
    padding: 1rem;
    &--active {
      background-color: $primary-active;
    }

    &:hover {
      cursor: pointer;
      background-color: $primary-hover;
    }
  }

  @mixin accordionList {
    @for $i from 1 through $levels {
      .accordion-child-level-#{$i} {
        & > {
          .accordion-child-label {
            position: sticky;
            top: calc(
              #{$navbar} + #{$goldenHeader} + calc(#{$childHeader} * #{$i}) - #{$childHeader}
            );
            z-index: calc(#{$levels} - #{$i});

            color: white;
            background-color: var(--secondary);
            padding: 1rem;
            padding-left: $i * 1rem;
            border-bottom: 1px solid white;
            &--active {
              background-color: $secondary-active;
            }

            &:hover {
              cursor: pointer;
              background-color: $secondary-hover;
            }
          }
          .accordion-child-content {
            padding-left: $i * 1rem + 1rem;
          }
        }
        & > * > .accordion-child-label-name {
          @include header-font(1.25rem);
        }
      }
    }
  }

  @include accordionList;
}
</style>
